<template>
    <section class="sec-registro-pagos">
        <div class="row">
            <div class="col-auto f-20 f-500 mr-auto">
                Registro de pagos
            </div>
            <div class="col-auto">
                <div class="row bg-f5 border br-8 indicadores">
                    <div class="col-auto my-auto">
                        <p class="f-17 f-500">
                            {{ cantViviendas }}
                            <span class="f-100 pl-2 f-13 text-muted">Viviendas</span>
                        </p>
                    </div>
                    <div class="col pr-0">
                        <div class="indicador1 d-middle-center">
                            <i class="icon-house-outline op-05" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <el-input v-model="search" placeholder="Busca por número de vivienda" size="small" prefix-icon="el-icon-search" class="wm-300px" clearable />
            </div>
        </div>
        <div class="row my-3">
            <div class="col-12 f-15 fr-light">
                Aquí puedes ver el historial de pago de cada vivienda y recibir los últimos soportes de pago de los residentes.
            </div>
        </div>
        <!-- filtros checkbox -->
        <div class="row">
            <div class="col-auto">
                <el-checkbox v-model="checkPendientes" @change="filtroEstados($event, 1)">
                    <span class="f-17 f-500 color-pendientes">{{ estados.pendiente }}</span> Pendientes
                </el-checkbox>
                <el-checkbox v-model="checkPagosSinVerificar" @change="filtroEstados($event, 2)">
                    <span class="f-17 f-500 color-sin-verificar">{{ estados.sinVerificar }}</span> Pagos sin verificar
                </el-checkbox>
                <el-checkbox v-model="checkEnMora" @change="filtroEstados($event, 3)">
                    <span class="f-17 f-500 color-mora">{{ estados.enMora }}</span> En mora
                </el-checkbox>
                <el-checkbox v-model="checkPazSalvo" @change="filtroEstados($event, 4)">
                    <span class="f-17 f-500 color-paz-salvo">{{ estados.pazYSalvo }}</span> Paz y Salvo
                </el-checkbox>
                <el-checkbox v-model="checkSinControl" @change="filtroEstados($event, null)">
                    <span class="f-17 f-500 color-sin-control">{{ estados.sinControlPagos }}</span> Sin control de pago ó datos
                </el-checkbox>
            </div>
            <div v-if="controlPagoActivo" class="col-auto ml-auto">
                <div class="d-middle">
                    <p class="f-15 fr-light mr-2">Sin controlar pagos</p>
                    <el-switch v-model="controlPagoActivo" :active-value="0" :inactive-value="1" active-color="#5A80EA" inactive-color="#E6E6E6" @change="putControlPagosActivo" />
                </div>
            </div>
        </div>
        <div v-if="!controlPagoActivo" class="row my-4">
            <div class="col-12">
                <div class="bg-f5 br-10 d-middle-bt p-3">
                    <div class="f-15">
                        <p class="mb-1"> Esta conjunto tiene la opción <span class="text-blue">"Sin control de pago"</span> activa. </p>
                        <p class="text-blue">¿Desea desactivar esta opción y tener el registro de pago de este conjunto?</p>
                    </div>
                    <div class="d-middle">
                        <p class="f-15 fr-light mr-2">Sin controlar pagos</p>
                        <el-switch v-model="controlPagoInactivo" :active-value="1" :inactive-value="0" active-color="#5A80EA" inactive-color="#E6E6E6" @change="putControlPagosInactivo" />
                    </div>
                </div>
            </div>
        </div>
        <!-- tabla -->
        <div class="row mt-4">
            <div class="col-12">
                <el-table :data="dataTable" height="calc(100vh - 310px)" header-cell-class-name="bg-f5 text-general" header-row-class-name="bg-f5 text-general rounded-circle" @row-click="verDetallesRegistro">
                    <template slot="empty">
                        <div class="wM-286px text-center mx-auto py-5">
                            <img src="/img/generales/buscar.svg" alt="" width="120" height="120" />
                            <p class="f-17 f-500 text-general  lh-20">No hemos encontrado coincidencias con tu búsqueda</p>
                        </div>
                    </template>
                    <el-table-column label="Vivienda" sortable min-width="180">
                        <template #default="scope">
                            <p class="text-general f-16 f-400">{{ scope.row.vivienda_nombre }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cant_users" label="Residentes" align="center" sortable min-width="180" />
                    <el-table-column label="Últ. Mes pagado" sortable min-width="180">
                        <template #default="scope">
                            <p class="text-general f-16 f-400 ucfirst">{{ scope.row.fecha_ultimo_pago | helper-fecha('MMMM YYYY') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Estado" sortable min-width="180">
                        <template #default="scope">
                            <div :class="`${colorEstados[scope.row.estado]} f-14 fr-light f-400 br-4 w-fit px-2 h-24px d-middle-center`">
                                {{ scope.row.estado ? textosEstados[scope.row.estado] : '-- --- ---' }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </section>
</template>
<script>
import Administracion from '~/services/pago_administracion'
import moment from 'moment'

moment.locale('es');

export default {
    name: 'RegistroPagos',
    data(){
        return {
            search: null,
            checkPendientes: false,
            checkPagosSinVerificar: false,
            checkEnMora: false,
            checkPazSalvo: false,
            checkSinControl: false,
            canPendientes: 250,
            cantPagosSinVerificar: 12,
            cantEnMora: 3,
            cantPazSalvo: 250,
            cantSinControl: 20,
            colorEstados:{
                1: 'bg-pendiente text-white',
                2: 'bg-sin-verificar text-white',
                3: 'bg-mora text-white',
                4: 'bg-paz-salvo text-white',
                5: 'bg-sin-control',
            },
            textosEstados:{
                1: 'Pago pendiente',
                2: 'Pago sin verificar',
                3: 'En mora',
                4: 'Paz y Salvo',
                5: 'Sin control de pago',
            },
            cantViviendas: 0,
            controlPagoActivo: 0,
            controlPagoInactivo: 0,
            viviendas: [],
            dataPrueba: [],
            estados: {
                sinVerificar: 0,
                pendiente: 0,
                enMora: 0,
                sinControlPagos: 0,
                pazYSalvo: 0,
            },
        }
    },
    computed: {
        dataTable(){
            return this.viviendas.filter(data => !this.search || data.vivienda.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    mounted(){
        this.getControlPagos();
        this.getRegistroPagos();
    },
    methods: {
        verDetallesRegistro(data){
            this.$router.push({ name: 'pagos.admin.registro.pagos.detail', params: {id: data.id} })
        },
        async getControlPagos(){
            try {
                const { data } = await Administracion.listarControlPagos();
                this.cantViviendas = data.data.cantViviendas;
                if (data.data.controlPago){
                    this.controlPagoActivo = 1;
                }else{
                    this.controlPagoInactivo = 1;
                }

            } catch (error){
                console.error(error);
            }
        },
        putControlPagosActivo(estado){
            this.controlPagoActivo = 0;
            this.controlPagoInactivo = 1;
            this.putControlPagos(0);
        },
        putControlPagosInactivo(estado){
            this.controlPagoActivo = 1;
            this.controlPagoInactivo = 0;
            this.putControlPagos(1);
        },
        async putControlPagos(estado){
            try {
                const payload = {
                    estado: estado
                };

                const { data } = await Administracion.updateControlPagos(payload);
                if (data.success){
                    this.getRegistroPagos();
                    this.notificacion('','Actualizado correctamente','success');
                }

            } catch (error){
                console.error(error);
            }
        },
        async getRegistroPagos(){
            try {
                const { data } = await Administracion.listarRegistroPagos();
                this.viviendas = data.data.viviendaPagos;
                this.dataPrueba = data.data.viviendaPagos;
                this.estados = data.data.estadosPagos;
            } catch (error){
                console.error(error);
            }
        },
        filtroEstados(val, estado){
            if (val) this.viviendas = this.viviendas.filter(data => data.estado == estado);
            else this.viviendas = this.dataPrueba;
        }
    },
}
</script>

<style lang="scss" scoped>
$pendientes: #5A80EACC;
$sinVerificar: #FF8316;
$enMora: #FF4F4F;
$pazSalvo: #28D07B;
$sinControl: #868686;
.color{
    &-pendientes{
        color: $pendientes;
    }
    &-sin-verificar{
        color: $sinVerificar;
    }
    &-mora{
        color: $enMora;
    }
    &-paz-salvo{
        color: $pazSalvo;
    }
    &-sin-control{
        color: $sinControl;
    }
}
.bg{
    &-pendiente{
        background: $pendientes;
    }
    &-sin-verificar{
        background: $sinVerificar;
    }
    &-mora{
        background: $enMora;
    }
    &-paz-salvo{
        background: $pazSalvo;
    }
    &-sin-control{
        background: #DBDBDB;
        color: #868686;
    }
}
</style>